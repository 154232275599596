import stsCoreTheme from "../sts-core-theme.";

const stsColorMap = {
  "border-accent-1-primary": stsCoreTheme.colors["secondary-color-2-700"],
  "border-accent-1-secondary": stsCoreTheme.colors["secondary-color-2-900"],
  "border-critical-primary": stsCoreTheme.colors["feedback-red-700"],
  "border-critical-secondary": stsCoreTheme.colors["feedback-red-300"],
  "border-default": stsCoreTheme.colors["neutral-400"],
  "border-disabled": stsCoreTheme.colors["neutral-400"],
  "border-info-primary": stsCoreTheme.colors["feedback-blue-700"],
  "border-info-secondary": stsCoreTheme.colors["feedback-blue-300"],
  "border-positive-primary": stsCoreTheme.colors["feedback-green-700"],
  "border-positive-secondary": stsCoreTheme.colors["feedback-green-300"],
  "border-select": stsCoreTheme.colors["feedback-blue-700"],
  "border-strong": stsCoreTheme.colors["neutral-900"],
  "border-strong-2": stsCoreTheme.colors["brand-main-700"],
  "border-subdued": stsCoreTheme.colors["neutral-300"],
  "border-transparent": stsCoreTheme.colors["gray-transparent"],
  "border-warning-primary": stsCoreTheme.colors["feedback-orange-700"],
  "border-warning-secondary": stsCoreTheme.colors["feedback-orange-300"],
  "icon-accent-4-primary": stsCoreTheme.colors["secondary-color-1-700"],
  "icon-accent-1": stsCoreTheme.colors["brand-main-700"],
  "icon-accent-2": stsCoreTheme.colors["secondary-color-2-700"],
  "icon-critical": stsCoreTheme.colors["feedback-red-300"],
  "icon-critical-2": stsCoreTheme.colors["feedback-red-500"],
  "icon-default": stsCoreTheme.colors["brand-main-700"],
  "icon-disabled": stsCoreTheme.colors["neutral-500"],
  "icon-duotone-1": stsCoreTheme.colors["brand-main-300"],
  "icon-duotone-2": stsCoreTheme.colors["secondary-color-2-300"],
  "icon-info": stsCoreTheme.colors["feedback-blue-700"],
  "icon-on-dark": stsCoreTheme.colors["neutral-50"],
  "icon-on-primary": stsCoreTheme.colors["neutral-900"],
  "icon-on-secondary": stsCoreTheme.colors["secondary-color-2-700"],
  "icon-positive": stsCoreTheme.colors["feedback-green-700"],
  "icon-subdued": stsCoreTheme.colors["neutral-700"],
  "icon-warning": stsCoreTheme.colors["feedback-orange-700"],
  "interactive-action-hovered": stsCoreTheme.colors["transparent-grey-300"],
  "interactive-action-pressed": stsCoreTheme.colors["transparent-grey-500"],
  "interactive-focus-default": stsCoreTheme.colors["feedback-blue-500"],
  "interactive-overlay-default": stsCoreTheme.colors["transparent-dark-500"],
  "interactive-overlay-image": stsCoreTheme.colors["transparent-light-700"],
  "interactive-overlay-boxcontent": stsCoreTheme.colors["transparent-dark-900"],
  "interactive-primary-default": stsCoreTheme.colors["secondary-yellow-700"],
  "interactive-primary-default-1": stsCoreTheme.colors["secondary-color-1-700"],
  "interactive-primary-hovered": stsCoreTheme.colors["secondary-yellow-500"],
  "interactive-primary-pressed": stsCoreTheme.colors["secondary-yellow-900"],
  "interactive-primary-pressed-2": stsCoreTheme.colors["secondary-color-1-900"],
  "interactive-secondary-default": stsCoreTheme.colors["secondary-color-2-700"],
  "interactive-secondary-hovered": stsCoreTheme.colors["secondary-color-2-500"],
  "interactive-secondary-pressed": stsCoreTheme.colors["secondary-color-2-900"],
  "surface-accent-1-primary": stsCoreTheme.colors["brand-main-100"],
  "surface-accent-1-secondary": stsCoreTheme.colors["brand-main-300"],
  "surface-accent-1-tertiary": stsCoreTheme.colors["brand-main-500"],
  "surface-accent-transparent": stsCoreTheme.colors["brand-purple-transparent"],
  "surface-accent-2-primary": stsCoreTheme.colors["secondary-color-2-500"],
  "surface-accent-2-secondary": stsCoreTheme.colors["secondary-color-2-300"],
  "surface-accent-3-primary": stsCoreTheme.colors["secondary-color-3-500"],
  "surface-accent-4-primary": stsCoreTheme.colors["secondary-color-3-700"],
  "surface-accent-4-secondary": stsCoreTheme.colors["secondary-color-3-700"], // not included figma. added to fix issues
  "surface-accent-3-secondary": stsCoreTheme.colors["secondary-color-3-300"],
  "surface-active": stsCoreTheme.colors["neutral-200"],
  "surface-critical": stsCoreTheme.colors["feedback-red-100"],
  "surface-disabled": stsCoreTheme.colors["neutral-200"],
  "surface-info": stsCoreTheme.colors["feedback-blue-300"],
  "surface-info-2": stsCoreTheme.colors["feedback-blue-100"],
  "surface-neutral-option-1": stsCoreTheme.colors["neutral-50"],
  "surface-neutral-option-10": stsCoreTheme.colors["neutral-900"],
  "surface-neutral-option-2": stsCoreTheme.colors["neutral-100"],
  "surface-neutral-option-3": stsCoreTheme.colors["neutral-200"],
  "surface-neutral-option-4": stsCoreTheme.colors["neutral-300"],
  "surface-neutral-option-5": stsCoreTheme.colors["neutral-400"],
  "surface-neutral-option-6": stsCoreTheme.colors["neutral-500"],
  "surface-neutral-option-7": stsCoreTheme.colors["neutral-600"],
  "surface-neutral-option-8": stsCoreTheme.colors["neutral-700"],
  "functional-subtle": stsCoreTheme.colors["neutral-700"],
  "surface-neutral-option-9": stsCoreTheme.colors["neutral-800"],
  "surface-positive": stsCoreTheme.colors["feedback-green-100"],
  "surface-promo": stsCoreTheme.colors["secondary-color-2-700"],
  "surface-select": stsCoreTheme.colors["secondary-color-2-500"],
  "surface-warning": stsCoreTheme.colors["feedback-orange-100"],
  "text-info-1-primary": stsCoreTheme.colors["neutral-900"],
  "text-accent-1-primary": stsCoreTheme.colors["brand-main-700"],
  "text-accent-3-primary": stsCoreTheme.colors["secondary-color-3-700"],
  "text-accent-4-primary": stsCoreTheme.colors["secondary-color-1-700"],
  "text-accent-4-secondary": stsCoreTheme.colors["secondary-color-1-900"],
  "text-body": stsCoreTheme.colors["neutral-800"],
  "text-critical": stsCoreTheme.colors["feedback-red-700"],
  "text-disabled": stsCoreTheme.colors["neutral-600"],
  "text-functional": stsCoreTheme.colors["neutral-900"],
  "text-functional-subtle": stsCoreTheme.colors["neutral-700"],
  "confirmation-body": stsCoreTheme.colors["neutral-900"],
  "confirmation-secondary-hover": stsCoreTheme.colors["secondary-color-2-700"],
  "confirmation-secondary-focus": stsCoreTheme.colors["neutral-900"],
  "text-info": stsCoreTheme.colors["feedback-blue-700"],
  "text-on-primary": stsCoreTheme.colors["neutral-900"],
  "text-on-primary-default": stsCoreTheme.colors["neutral-900"],
  "text-on-secondary": stsCoreTheme.colors["secondary-color-2-700"],
  "text-placeholder": stsCoreTheme.colors["neutral-600"],
  "text-placeholder-secondary": stsCoreTheme.colors["neutral-1000"],
  "text-positive": stsCoreTheme.colors["feedback-green-700"],
  "text-select": stsCoreTheme.colors["feedback-blue-500"],
  "text-title": stsCoreTheme.colors["brand-main-700"],
  "text-warning": stsCoreTheme.colors["feedback-orange-700"],
  "transparent-overlay": stsCoreTheme.colors["transparent-overlay"],
  "transparent-overlay-1": stsCoreTheme.colors["transparent-overlay-1"],
  "nhs-header-text": stsCoreTheme.colors["neutral-50"],
  "nhs-paragraph-text": stsCoreTheme.colors["neutral-100"],
  "secondary-inactive-hover": stsCoreTheme.colors["secondary-color-2-900"],
  "secondary-active-hover": stsCoreTheme.colors["secondary-color-2-900"],

  dark: {
    "border-accent-1-primary": stsCoreTheme.colors["secondary-color-2-500"],
    "border-accent-1-secondary": stsCoreTheme.colors["secondary-color-2-300"],
    "border-critical-primary": stsCoreTheme.colors["feedback-red-300"],
    "border-critical-secondary": stsCoreTheme.colors["feedback-red-700"],
    "border-default": stsCoreTheme.colors["neutral-600"],
    "border-disabled": stsCoreTheme.colors["neutral-600"],
    "border-info-primary": stsCoreTheme.colors["feedback-blue-300"],
    "border-info-secondary": stsCoreTheme.colors["feedback-blue-700"],
    "border-positive-primary": stsCoreTheme.colors["feedback-green-300"],
    "border-positive-secondary": stsCoreTheme.colors["feedback-green-700"],
    "border-select": stsCoreTheme.colors["feedback-blue-300"],
    "border-strong": stsCoreTheme.colors["neutral-50"],
    "border-strong-2": stsCoreTheme.colors["brand-main-100"],
    "border-subdued": stsCoreTheme.colors["neutral-700"],
    "border-warning-primary": stsCoreTheme.colors["feedback-orange-300"],
    "border-warning-secondary": stsCoreTheme.colors["feedback-orange-700"],
    "icon-accent-1": stsCoreTheme.colors["brand-main-300"],
    "icon-accent-2": stsCoreTheme.colors["secondary-color-2-300"],
    "icon-accent-4-primary": stsCoreTheme.colors["secondary-color-1-700"], // not included figma. added to fix issues
    "icon-critical": stsCoreTheme.colors["feedback-red-300"],
    "icon-default": stsCoreTheme.colors["neutral-50"],
    "icon-disabled": stsCoreTheme.colors["neutral-500"],
    "icon-duotone-1": stsCoreTheme.colors["brand-main-300"],
    "icon-duotone-2": stsCoreTheme.colors["secondary-color-2-300"],
    "icon-info": stsCoreTheme.colors["feedback-blue-300"],
    "icon-on-dark": stsCoreTheme.colors["neutral-900"],
    "icon-on-primary": stsCoreTheme.colors["neutral-50"],
    "icon-on-secondary": stsCoreTheme.colors["neutral-50"],
    "icon-positive": stsCoreTheme.colors["feedback-green-300"],
    "icon-subdued": stsCoreTheme.colors["neutral-200"],
    "icon-warning": stsCoreTheme.colors["feedback-orange-300"],
    "interactive-action-hovered": stsCoreTheme.colors["transparent-light-300"],
    "interactive-action-pressed": stsCoreTheme.colors["transparent-light-500"],
    "interactive-focus-default": stsCoreTheme.colors["feedback-blue-500"],
    "interactive-overlay-default": stsCoreTheme.colors["transparent-dark-500"],
    "interactive-overlay-image": stsCoreTheme.colors["transparent-dark-700"],
    "interactive-primary-default": stsCoreTheme.colors["secondary-yellow-700"],
    "interactive-primary-hovered": stsCoreTheme.colors["secondary-yellow-500"],
    "interactive-primary-pressed": stsCoreTheme.colors["secondary-yellow-900"],
    "interactive-secondary-default":
      stsCoreTheme.colors["secondary-color-2-700"],
    "interactive-secondary-hovered":
      stsCoreTheme.colors["secondary-color-2-500"],
    "interactive-secondary-pressed":
      stsCoreTheme.colors["secondary-color-2-900"],
    "surface-accent-1-primary": stsCoreTheme.colors["brand-main-700"],
    "surface-accent-1-secondary": stsCoreTheme.colors["brand-main-500"],
    "surface-accent-2-primary": stsCoreTheme.colors["secondary-color-2-700"],
    "surface-accent-2-secondary": stsCoreTheme.colors["secondary-color-2-900"],
    "surface-accent-3-primary": stsCoreTheme.colors["secondary-color-3-700"],
    "surface-accent-3-secondary": stsCoreTheme.colors["secondary-color-3-900"],
    "surface-accent-4-primary": stsCoreTheme.colors["secondary-color-3-500"],
    "surface-active": stsCoreTheme.colors["neutral-700"],
    "surface-critical": stsCoreTheme.colors["feedback-red-700"],
    "surface-disabled": stsCoreTheme.colors["neutral-700"],
    "surface-info": stsCoreTheme.colors["feedback-blue-700"],
    "surface-info-2": stsCoreTheme.colors["feedback-blue-500"],
    "surface-neutral-option-1": stsCoreTheme.colors["neutral-900"],
    "surface-neutral-option-10": stsCoreTheme.colors["neutral-50"],
    "surface-neutral-option-2": "neutral-800",
    "surface-neutral-option-3": stsCoreTheme.colors["neutral-700"],
    "surface-neutral-option-4": stsCoreTheme.colors["neutral-600"],
    "surface-neutral-option-5": stsCoreTheme.colors["neutral-500"],
    "surface-neutral-option-6": stsCoreTheme.colors["neutral-400"],
    "surface-neutral-option-7": stsCoreTheme.colors["neutral-300"],
    "surface-neutral-option-8": stsCoreTheme.colors["neutral-200"],
    "surface-neutral-option-9": stsCoreTheme.colors["feedback-red-700"],
    "surface-positive": stsCoreTheme.colors["feedback-green-700"],
    "surface-promo": stsCoreTheme.colors["secondary-color-2-700"],
    "surface-select": stsCoreTheme.colors["secondary-color-2-700"],
    "surface-warning": stsCoreTheme.colors["feedback-orange-700"],
    "text-accent-1-primary": stsCoreTheme.colors["brand-main-100"],
    "text-accent-3-primary": stsCoreTheme.colors["secondary-color-3-300"],
    "text-accent-4-primary": stsCoreTheme.colors["secondary-color-1-300"],
    "text-on-primary-default": stsCoreTheme.colors["neutral-900"],
    "text-accent-4-secondary": stsCoreTheme.colors["secondary-color-1-500"],
    "text-body": stsCoreTheme.colors["neutral-100"],
    "text-critical": stsCoreTheme.colors["feedback-red-300"],
    "text-disabled": stsCoreTheme.colors["neutral-600"],
    "text-functional": stsCoreTheme.colors["neutral-100"],
    "text-functional-subtle": stsCoreTheme.colors["neutral-200"],
    "text-info": stsCoreTheme.colors["feedback-blue-300"],
    "text-on-primary": stsCoreTheme.colors["neutral-50"],
    "text-on-secondary": stsCoreTheme.colors["neutral-50"],
    "text-placeholder": stsCoreTheme.colors["neutral-300"],
    "text-positive": stsCoreTheme.colors["feedback-green-300"],
    "text-select": stsCoreTheme.colors["feedback-blue-500"],
    "text-title": stsCoreTheme.colors["neutral-50"],
    "text-warning": stsCoreTheme.colors["feedback-orange-300"],
  },
};

export default stsColorMap;
