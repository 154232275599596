import avantiCoreTheme from "../avanti-core-theme";

const avantiColorMap = {
  /* Light Background */
  /* color */
  "border-accent-1-primary": avantiCoreTheme.colors["brand-main-700"], // in figma border-accent-2-primary
  "border-accent-1-secondary": avantiCoreTheme.colors["brand-main-500"], // in figma border-accent-2-secondary
  "border-accent-2-primary": avantiCoreTheme.colors["brand-main-700"],
  "border-accent-2-secondary": avantiCoreTheme.colors["brand-main-500"],
  "border-critical-primary": avantiCoreTheme.colors["feedback-red-700"],
  "border-critical-secondary": avantiCoreTheme.colors["feedback-red-300"],
  "border-default": avantiCoreTheme.colors["neutral-400"],
  "border-disabled": avantiCoreTheme.colors["neutral-400"],
  "border-info-primary": avantiCoreTheme.colors["feedback-blue-700"],
  "border-info-secondary": avantiCoreTheme.colors["feedback-blue-300"],
  "border-positive-primary": avantiCoreTheme.colors["feedback-green-700"],
  "border-positive-secondary": avantiCoreTheme.colors["feedback-green-300"],
  "border-select": avantiCoreTheme.colors["feedback-blue-700"],
  "border-strong": avantiCoreTheme.colors["neutral-900"],
  "border-strong-2": avantiCoreTheme.colors["brand-main-700"],
  "border-subdued": avantiCoreTheme.colors["neutral-300"],
  "border-warning-primary": avantiCoreTheme.colors["feedback-orange-700"],
  "border-warning-secondary": avantiCoreTheme.colors["feedback-orange-300"],
  "icon-accent-1": avantiCoreTheme.colors["brand-main-700"],
  "icon-accent-2": avantiCoreTheme.colors["secondary-color-2-700"],
  "icon-accent-3": avantiCoreTheme.colors["secondary-color-3-700"],
  "icon-accent-4-primary": avantiCoreTheme.colors["secondary-color-1-700"],
  "icon-accent-4-secondary": avantiCoreTheme.colors["secondary-color-1-900"],
  "icon-critical": avantiCoreTheme.colors["feedback-red-500"],
  "icon-default": avantiCoreTheme.colors["neutral-900"],
  "icon-disabled": avantiCoreTheme.colors["neutral-600"],
  "icon-duotone-1": avantiCoreTheme.colors["neutral-900"],
  "icon-duotone-2": avantiCoreTheme.colors["brand-main-100"],
  "icon-info": avantiCoreTheme.colors["feedback-blue-700"],
  "icon-on-dark": avantiCoreTheme.colors["neutral-50"],
  "icon-on-primary": avantiCoreTheme.colors["neutral-900"],
  "icon-on-secondary": avantiCoreTheme.colors["secondary-color-2-700"],
  "icon-positive": avantiCoreTheme.colors["feedback-green-700"],
  "icon-subdued": avantiCoreTheme.colors["neutral-700"],
  "icon-warning": avantiCoreTheme.colors["feedback-orange-700"],
  "interactive-action-hovered": avantiCoreTheme.colors["transparent-grey-300"],
  "interactive-action-pressed": avantiCoreTheme.colors["transparent-grey-500"],
  "interactive-focus-default": avantiCoreTheme.colors["feedback-blue-500"],
  "interactive-overlay-default": avantiCoreTheme.colors["transparent-dark-700"],
  "interactive-overlay-image": avantiCoreTheme.colors["transparent-light-700"],
  "interactive-overlay-subtle": avantiCoreTheme.colors["transparent-dark-500"],
  "interactive-overlay-boxcontent":
    avantiCoreTheme.colors["transparent-dark-900"],
  "interactive-primary-default":
    avantiCoreTheme.colors["secondary-color-1-700"],
  "interactive-primary-default-1":
    avantiCoreTheme.colors["secondary-color-1-700"], // added to fix issues
  "interactive-primary-hovered":
    avantiCoreTheme.colors["secondary-color-1-500"],
  "interactive-primary-pressed":
    avantiCoreTheme.colors["secondary-color-1-900"],
  "interactive-primary-pressed-2":
    avantiCoreTheme.colors["secondary-color-1-900"],
  "interactive-secondary-default": avantiCoreTheme.colors["brand-main-700"],
  "interactive-secondary-hovered": avantiCoreTheme.colors["brand-main-900"],
  "interactive-secondary-pressed": avantiCoreTheme.colors["brand-main-700"],
  "surface-accent-2-primary": avantiCoreTheme.colors["secondary-color-2-300"], // in figma surface-accent-1-primary
  "surface-accent-2-secondary": avantiCoreTheme.colors["secondary-color-2-500"], // in figma surface-accent-1-secondary
  "surface-accent-1-primary": avantiCoreTheme.colors["brand-main-100"], // in figma surface-accent-2-primary
  "surface-accent-1-secondary": avantiCoreTheme.colors["brand-main-300"], // in figma surface-accent-2-secondary
  "surface-accent-2-tertiary": avantiCoreTheme.colors["brand-main-900"],
  "surface-accent-3-primary": avantiCoreTheme.colors["secondary-color-3-500"],
  "surface-accent-3-secondary": avantiCoreTheme.colors["secondary-color-3-300"],
  "surface-accent-4-primary": avantiCoreTheme.colors["secondary-color-4-500"],
  "surface-accent-4-secondary": avantiCoreTheme.colors["secondary-color-4-300"],
  "surface-accent-5-primary": avantiCoreTheme.colors["secondary-color-5-500"],
  "surface-accent-5-secondary": avantiCoreTheme.colors["secondary-color-5-300"],
  "surface-active": avantiCoreTheme.colors["neutral-200"],
  "surface-critical": avantiCoreTheme.colors["feedback-red-100"],
  "surface-disabled": avantiCoreTheme.colors["neutral-200"],
  "surface-info": avantiCoreTheme.colors["feedback-blue-300"],
  "surface-info-2": avantiCoreTheme.colors["feedback-blue-100"],
  "surface-neutral-option-1": avantiCoreTheme.colors["neutral-50"],
  "surface-neutral-option-10": avantiCoreTheme.colors["neutral-900"],
  "surface-neutral-option-2": avantiCoreTheme.colors["neutral-100"],
  "surface-neutral-option-3": avantiCoreTheme.colors["neutral-200"],
  "surface-neutral-option-4": avantiCoreTheme.colors["neutral-300"],
  "surface-neutral-option-5": avantiCoreTheme.colors["neutral-400"],
  "surface-neutral-option-6": avantiCoreTheme.colors["neutral-500"],
  "surface-neutral-option-7": avantiCoreTheme.colors["neutral-600"],
  "surface-neutral-option-8": avantiCoreTheme.colors["neutral-700"],
  "surface-neutral-option-9": avantiCoreTheme.colors["neutral-800"],
  "functional-subtle": avantiCoreTheme.colors["neutral-1000"],
  "surface-positive": avantiCoreTheme.colors["feedback-green-100"],
  "surface-promo": avantiCoreTheme.colors["secondary-color-2-700"],
  "surface-select": avantiCoreTheme.colors["secondary-color-2-500"],
  "surface-warning": avantiCoreTheme.colors["feedback-orange-100"],
  "text-info-1-primary": avantiCoreTheme.colors["neutral-800"],
  "text-accent-1-primary": avantiCoreTheme.colors["brand-main-700"],
  "text-accent-3-primary": avantiCoreTheme.colors["secondary-color-3-700"],
  "text-accent-4-primary": avantiCoreTheme.colors["secondary-color-1-700"],
  "text-accent-4-secondary": avantiCoreTheme.colors["secondary-color-1-900"],
  "text-body": avantiCoreTheme.colors["neutral-800"],
  "text-critical-primary": avantiCoreTheme.colors["feedback-red-700"],
  "text-critical": avantiCoreTheme.colors["feedback-red-700"], // added to fix issues
  "text-critical-secondary": avantiCoreTheme.colors["feedback-red-500"],
  "text-disabled": avantiCoreTheme.colors["neutral-600"],
  "text-functional": avantiCoreTheme.colors["neutral-900"],
  "text-functional-subtle": avantiCoreTheme.colors["neutral-700"],
  "text-info": avantiCoreTheme.colors["feedback-blue-700"],
  "text-on-primary": avantiCoreTheme.colors["neutral-900"],
  "text-on-primary-default": avantiCoreTheme.colors["neutral-default"],
  "text-on-secondary": avantiCoreTheme.colors["secondary-color-2-700"],
  "text-placeholder": avantiCoreTheme.colors["neutral-700"],
  "text-placeholder-secondary": avantiCoreTheme.colors["neutral-1000"],
  "text-positive": avantiCoreTheme.colors["feedback-green-700"],
  "text-select": avantiCoreTheme.colors["feedback-blue-500"],
  "text-title": avantiCoreTheme.colors["brand-main-700"],
  "text-warning": avantiCoreTheme.colors["feedback-orange-700"],
  "confirmation-body": avantiCoreTheme.colors["neutral-800"],
  "confirmation-secondary-hover": avantiCoreTheme.colors["brand-main-900"],
  "confirmation-secondary-focus": avantiCoreTheme.colors["brand-main-900"],
  "secondary-inactive-hover": avantiCoreTheme.colors["brand-main-700"],
  "secondary-active-hover": avantiCoreTheme.colors["brand-main-700"],
  "transparent-overlay": avantiCoreTheme.colors["transparent-overlay"], // not included figma
  "transparent-overlay-1": avantiCoreTheme.colors["transparent-overlay-1"], // not included figma
  "surface-accent-transparent":
    avantiCoreTheme.colors["brand-purple-transparent"], // not included figma
  "border-transparent": avantiCoreTheme.colors["gray-transparent"], // not included figma
  /* Dark Background */
  /* color */
  dark: {
    "border-accent-1-primary": avantiCoreTheme.colors["neutral-50"], // in figma border-accent-2-primary
    "border-accent-1-secondary": avantiCoreTheme.colors["neutral-50"], // in figma border-accent-2-secondary
    "border-accent-2-primary": avantiCoreTheme.colors["neutral-50"],
    "border-accent-2-secondary": avantiCoreTheme.colors["neutral-50"],
    "border-critical-primary": avantiCoreTheme.colors["feedback-red-300"],
    "border-critical-secondary": avantiCoreTheme.colors["feedback-red-700"],
    "border-default": avantiCoreTheme.colors["neutral-600"],
    "border-disabled": avantiCoreTheme.colors["neutral-600"],
    "border-info-primary": avantiCoreTheme.colors["feedback-blue-300"],
    "border-info-secondary": avantiCoreTheme.colors["feedback-blue-700"],
    "border-positive-primary": avantiCoreTheme.colors["feedback-green-300"],
    "border-positive-secondary": avantiCoreTheme.colors["feedback-green-700"],
    "border-select": avantiCoreTheme.colors["feedback-blue-300"],
    "border-strong": avantiCoreTheme.colors["neutral-50"],
    "border-strong-2": avantiCoreTheme.colors["brand-main-100"],
    "border-subdued": avantiCoreTheme.colors["neutral-700"],
    "border-warning-primary": avantiCoreTheme.colors["feedback-orange-300"],
    "border-warning-secondary": avantiCoreTheme.colors["feedback-orange-700"],
    "icon-accent-1": avantiCoreTheme.colors["brand-main-300"],
    "icon-accent-2": avantiCoreTheme.colors["neutral-50"],
    "icon-accent-3": avantiCoreTheme.colors["secondary-color-3-700"],
    "icon-accent-4-primary": avantiCoreTheme.colors["secondary-color-1-300"],
    "icon-accent-4-secondary": avantiCoreTheme.colors["secondary-color-1-500"],
    "icon-critical": avantiCoreTheme.colors["feedback-red-300"],
    "icon-default": avantiCoreTheme.colors["neutral-50"],
    "icon-disabled": avantiCoreTheme.colors["neutral-600"],
    "icon-duotone-1": avantiCoreTheme.colors["neutral-50"],
    "icon-duotone-2": avantiCoreTheme.colors["transparent-light-300"],
    "icon-info": avantiCoreTheme.colors["feedback-blue-300"],
    "icon-on-dark": avantiCoreTheme.colors["neutral-500"],
    "icon-on-primary": avantiCoreTheme.colors["neutral-900"],
    "icon-on-secondary": avantiCoreTheme.colors["neutral-50"],
    "icon-positive": avantiCoreTheme.colors["feedback-green-300"],
    "icon-subdued": avantiCoreTheme.colors["neutral-200"],
    "icon-warning": avantiCoreTheme.colors["feedback-orange-300"],
    "interactive-action-hovered":
      avantiCoreTheme.colors["transparent-light-300"],
    "interactive-action-pressed":
      avantiCoreTheme.colors["transparent-light-500"],
    "interactive-focus-default": avantiCoreTheme.colors["feedback-blue-500"],
    "interactive-overlay-default":
      avantiCoreTheme.colors["transparent-light-700"],
    "interactive-overlay-image":
      avantiCoreTheme.colors["transparent-light-700"],
    "interactive-overlay-subtle":
      avantiCoreTheme.colors["transparent-light-500"],
    "interactive-primary-default": avantiCoreTheme.colors["neutral-50"],
    "interactive-primary-hovered": avantiCoreTheme.colors["neutral-50"],
    "interactive-primary-pressed": avantiCoreTheme.colors["neutral-50"],
    "interactive-secondary-default": avantiCoreTheme.colors["neutral-900"],
    "interactive-secondary-hovered": avantiCoreTheme.colors["neutral-800"],
    "interactive-secondary-pressed": avantiCoreTheme.colors["neutral-900"],
    "surface-accent-2-primary": avantiCoreTheme.colors["secondary-color-2-700"], // in figma surface-accent-1-primary
    "surface-accent-2-secondary":
      avantiCoreTheme.colors["secondary-color-2-900"], // in figma surface-accent-1-secondary
    "surface-accent-1-primary": avantiCoreTheme.colors["brand-main-700"], // in figma surface-accent-2-primary
    "surface-accent-1-secondary": avantiCoreTheme.colors["brand-main-500"], // in figma surface-accent-2-secondary
    "surface-accent-2-tertiary": avantiCoreTheme.colors["brand-main-900"],
    "surface-accent-3-primary": avantiCoreTheme.colors["secondary-color-3-700"],
    "surface-accent-3-secondary":
      avantiCoreTheme.colors["secondary-color-3-900"],
    "surface-accent-4-primary": avantiCoreTheme.colors["secondary-color-4-700"],
    "surface-accent-4-secondary":
      avantiCoreTheme.colors["secondary-color-4-900"],
    "surface-accent-5-primary": avantiCoreTheme.colors["secondary-color-5-700"],
    "surface-accent-5-secondary":
      avantiCoreTheme.colors["secondary-color-5-900"],
    "surface-active": avantiCoreTheme.colors["neutral-700"],
    "surface-critical": avantiCoreTheme.colors["feedback-red-700"],
    "surface-disabled": avantiCoreTheme.colors["neutral-700"],
    "surface-info": avantiCoreTheme.colors["feedback-blue-700"],
    "surface-info-2": avantiCoreTheme.colors["feedback-blue-500"],
    "surface-neutral-option-1": avantiCoreTheme.colors["neutral-900"],
    "surface-neutral-option-10": avantiCoreTheme.colors["neutral-50"],
    "surface-neutral-option-2": avantiCoreTheme.colors["neutral-800"],
    "surface-neutral-option-3": avantiCoreTheme.colors["neutral-700"],
    "surface-neutral-option-4": avantiCoreTheme.colors["neutral-600"],
    "surface-neutral-option-5": avantiCoreTheme.colors["neutral-500"],
    "surface-neutral-option-6": avantiCoreTheme.colors["neutral-400"],
    "surface-neutral-option-7": avantiCoreTheme.colors["neutral-300"],
    "surface-neutral-option-8": avantiCoreTheme.colors["neutral-200"],
    "surface-neutral-option-9": avantiCoreTheme.colors["neutral-100"],
    "surface-positive": avantiCoreTheme.colors["feedback-green-700"],
    "surface-promo": avantiCoreTheme.colors["secondary-color-2-700"],
    "surface-select": avantiCoreTheme.colors["secondary-color-2-700"],
    "surface-warning": avantiCoreTheme.colors["feedback-orange-700"],
    "text-accent-1-primary": avantiCoreTheme.colors["brand-main-100"],
    "text-accent-3-primary": avantiCoreTheme.colors["secondary-color-3-300"],
    "text-accent-4-primary": avantiCoreTheme.colors["secondary-color-1-300"],
    "text-accent-4-secondary": avantiCoreTheme.colors["secondary-color-1-500"],
    "text-body": avantiCoreTheme.colors["neutral-100"],
    "text-critical-primary": avantiCoreTheme.colors["feedback-red-300"],
    "text-critical": avantiCoreTheme.colors["feedback-red-300"], // added to fix issues
    "text-critical-secondary": avantiCoreTheme.colors["feedback-red-100"],
    "text-disabled": avantiCoreTheme.colors["neutral-600"],
    "text-functional": avantiCoreTheme.colors["neutral-50"],
    "text-functional-subtle": avantiCoreTheme.colors["neutral-200"],
    "text-info": avantiCoreTheme.colors["feedback-blue-300"],
    "text-on-primary": avantiCoreTheme.colors["neutral-50"],
    "text-on-secondary": avantiCoreTheme.colors["neutral-50"],
    "text-placeholder": avantiCoreTheme.colors["neutral-300"],
    "text-positive": avantiCoreTheme.colors["feedback-green-300"],
    "text-select": avantiCoreTheme.colors["feedback-blue-500"],
    "text-title": avantiCoreTheme.colors["neutral-50"],
    "text-warning": avantiCoreTheme.colors["feedback-orange-300"],
  },
};

export default avantiColorMap;
