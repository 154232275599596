import expatCoreTheme from "../expat-core-theme";

const expatColorMap = {
  "border-accent-1-primary": expatCoreTheme.colors["secondary-color-2-700"],
  "border-accent-1-secondary": expatCoreTheme.colors["secondary-color-2-900"],
  "border-critical-primary": expatCoreTheme.colors["feedback-red-700"],
  "border-critical-secondary": expatCoreTheme.colors["feedback-red-300"],
  "border-default": expatCoreTheme.colors["neutral-400"],
  "border-disabled": expatCoreTheme.colors["neutral-400"],
  "border-info-primary": expatCoreTheme.colors["feedback-blue-700"],
  "border-info-secondary": expatCoreTheme.colors["feedback-blue-300"],
  "border-positive-primary": expatCoreTheme.colors["feedback-green-700"],
  "border-positive-secondary": expatCoreTheme.colors["feedback-green-300"],
  "border-select": expatCoreTheme.colors["feedback-blue-700"],
  "border-strong": expatCoreTheme.colors["neutral-900"],
  "border-strong-2": expatCoreTheme.colors["brand-main-700"],
  "border-subdued": expatCoreTheme.colors["neutral-300"],
  "border-transparent": expatCoreTheme.colors["gray-transparent"],
  "border-warning-primary": expatCoreTheme.colors["feedback-orange-700"],
  "border-warning-secondary": expatCoreTheme.colors["feedback-orange-300"],
  "icon-accent-4-primary": expatCoreTheme.colors["secondary-color-1-700"],
  "icon-accent-1": expatCoreTheme.colors["brand-main-700"],
  "icon-accent-2": expatCoreTheme.colors["secondary-color-2-700"],
  "icon-critical": expatCoreTheme.colors["feedback-red-300"],
  "icon-critical-2": expatCoreTheme.colors["feedback-red-500"],
  "icon-default": expatCoreTheme.colors["brand-main-700"],
  "icon-disabled": expatCoreTheme.colors["neutral-500"],
  "icon-duotone-1": expatCoreTheme.colors["brand-main-300"],
  "icon-duotone-2": expatCoreTheme.colors["secondary-color-2-300"],
  "icon-info": expatCoreTheme.colors["feedback-blue-700"],
  "icon-on-dark": expatCoreTheme.colors["neutral-50"],
  "icon-on-primary": expatCoreTheme.colors["neutral-900"],
  "icon-on-secondary": expatCoreTheme.colors["secondary-color-2-700"],
  "icon-positive": expatCoreTheme.colors["feedback-green-700"],
  "icon-subdued": expatCoreTheme.colors["neutral-700"],
  "icon-warning": expatCoreTheme.colors["feedback-orange-700"],
  "interactive-action-hovered": expatCoreTheme.colors["transparent-grey-300"],
  "interactive-action-pressed": expatCoreTheme.colors["transparent-grey-500"],
  "interactive-focus-default": expatCoreTheme.colors["feedback-blue-500"],
  "interactive-overlay-default": expatCoreTheme.colors["transparent-dark-500"],
  "interactive-overlay-image": expatCoreTheme.colors["transparent-light-700"],
  "interactive-overlay-boxcontent":
    expatCoreTheme.colors["transparent-dark-900"],
  "interactive-primary-default": expatCoreTheme.colors["secondary-yellow-700"],
  "interactive-primary-default-1":
    expatCoreTheme.colors["secondary-color-1-700"],
  "interactive-primary-hovered": expatCoreTheme.colors["secondary-yellow-500"],
  "interactive-primary-pressed": expatCoreTheme.colors["secondary-yellow-900"],
  "interactive-primary-pressed-2":
    expatCoreTheme.colors["secondary-color-1-900"],
  "interactive-secondary-default":
    expatCoreTheme.colors["secondary-color-2-700"],
  "interactive-secondary-hovered":
    expatCoreTheme.colors["secondary-color-2-500"],
  "interactive-secondary-pressed":
    expatCoreTheme.colors["secondary-color-2-900"],
  "surface-accent-1-primary": expatCoreTheme.colors["brand-main-100"],
  "surface-accent-1-secondary": expatCoreTheme.colors["brand-main-300"],
  "surface-accent-1-tertiary": expatCoreTheme.colors["brand-main-500"],
  "surface-accent-transparent":
    expatCoreTheme.colors["brand-purple-transparent"],
  "surface-accent-2-primary": expatCoreTheme.colors["secondary-color-2-500"],
  "surface-accent-2-secondary": expatCoreTheme.colors["secondary-color-2-300"],
  "surface-accent-3-primary": expatCoreTheme.colors["secondary-color-3-500"],
  "surface-accent-4-primary": expatCoreTheme.colors["secondary-color-3-700"],
  "surface-accent-4-secondary": expatCoreTheme.colors["secondary-color-3-700"], // not included figma. added to fix issues
  "surface-accent-3-secondary": expatCoreTheme.colors["secondary-color-3-300"],
  "surface-active": expatCoreTheme.colors["neutral-200"],
  "surface-critical": expatCoreTheme.colors["feedback-red-100"],
  "surface-disabled": expatCoreTheme.colors["neutral-200"],
  "surface-info": expatCoreTheme.colors["feedback-blue-300"],
  "surface-info-2": expatCoreTheme.colors["feedback-blue-100"],
  "surface-neutral-option-1": expatCoreTheme.colors["neutral-50"],
  "surface-neutral-option-10": expatCoreTheme.colors["neutral-900"],
  "surface-neutral-option-2": expatCoreTheme.colors["neutral-100"],
  "surface-neutral-option-3": expatCoreTheme.colors["neutral-200"],
  "surface-neutral-option-4": expatCoreTheme.colors["neutral-300"],
  "surface-neutral-option-5": expatCoreTheme.colors["neutral-400"],
  "surface-neutral-option-6": expatCoreTheme.colors["neutral-500"],
  "surface-neutral-option-7": expatCoreTheme.colors["neutral-600"],
  "surface-neutral-option-8": expatCoreTheme.colors["neutral-700"],
  "functional-subtle": expatCoreTheme.colors["neutral-700"],
  "surface-neutral-option-9": expatCoreTheme.colors["neutral-800"],
  "surface-positive": expatCoreTheme.colors["feedback-green-100"],
  "surface-promo": expatCoreTheme.colors["secondary-color-2-700"],
  "surface-select": expatCoreTheme.colors["secondary-color-2-500"],
  "surface-warning": expatCoreTheme.colors["feedback-orange-100"],
  "text-accent-1-primary": expatCoreTheme.colors["brand-main-700"],
  "text-accent-3-primary": expatCoreTheme.colors["secondary-color-3-700"],
  "text-accent-4-primary": expatCoreTheme.colors["secondary-color-1-700"],
  "text-accent-4-secondary": expatCoreTheme.colors["secondary-color-1-900"],
  "text-body": expatCoreTheme.colors["neutral-800"],
  "text-critical": expatCoreTheme.colors["feedback-red-700"],
  "text-disabled": expatCoreTheme.colors["neutral-600"],
  "text-functional": expatCoreTheme.colors["neutral-900"],
  "text-functional-subtle": expatCoreTheme.colors["neutral-700"],
  "confirmation-body": expatCoreTheme.colors["neutral-900"],
  "confirmation-secondary-hover":
    expatCoreTheme.colors["secondary-color-2-700"],
  "confirmation-secondary-focus": expatCoreTheme.colors["neutral-900"],
  "text-info": expatCoreTheme.colors["feedback-blue-700"],
  "text-on-primary": expatCoreTheme.colors["neutral-900"],
  "text-on-primary-default": expatCoreTheme.colors["neutral-900"],
  "text-on-secondary": expatCoreTheme.colors["secondary-color-2-700"],
  "text-placeholder": expatCoreTheme.colors["neutral-600"],
  "text-positive": expatCoreTheme.colors["feedback-green-700"],
  "text-select": expatCoreTheme.colors["feedback-blue-500"],
  "text-title": expatCoreTheme.colors["brand-main-700"],
  "text-warning": expatCoreTheme.colors["feedback-orange-700"],
  "transparent-overlay": expatCoreTheme.colors["transparent-overlay"],
  "transparent-overlay-1": expatCoreTheme.colors["transparent-overlay-1"],
  "nhs-header-text": expatCoreTheme.colors["neutral-50"],
  "nhs-paragraph-text": expatCoreTheme.colors["neutral-100"],
  "secondary-inactive-hover": expatCoreTheme.colors["secondary-color-2-900"],
  "secondary-active-hover": expatCoreTheme.colors["secondary-color-2-900"],

  dark: {
    "border-accent-1-primary": expatCoreTheme.colors["secondary-color-2-500"],
    "border-accent-1-secondary": expatCoreTheme.colors["secondary-color-2-300"],
    "border-critical-primary": expatCoreTheme.colors["feedback-red-300"],
    "border-critical-secondary": expatCoreTheme.colors["feedback-red-700"],
    "border-default": expatCoreTheme.colors["neutral-600"],
    "border-disabled": expatCoreTheme.colors["neutral-600"],
    "border-info-primary": expatCoreTheme.colors["feedback-blue-300"],
    "border-info-secondary": expatCoreTheme.colors["feedback-blue-700"],
    "border-positive-primary": expatCoreTheme.colors["feedback-green-300"],
    "border-positive-secondary": expatCoreTheme.colors["feedback-green-700"],
    "border-select": expatCoreTheme.colors["feedback-blue-300"],
    "border-strong": expatCoreTheme.colors["neutral-50"],
    "border-strong-2": expatCoreTheme.colors["brand-main-100"],
    "border-subdued": expatCoreTheme.colors["neutral-700"],
    "border-warning-primary": expatCoreTheme.colors["feedback-orange-300"],
    "border-warning-secondary": expatCoreTheme.colors["feedback-orange-700"],
    "icon-accent-1": expatCoreTheme.colors["brand-main-300"],
    "icon-accent-2": expatCoreTheme.colors["secondary-color-2-300"],
    "icon-accent-4-primary": expatCoreTheme.colors["secondary-color-1-700"], // not included figma. added to fix issues
    "icon-critical": expatCoreTheme.colors["feedback-red-300"],
    "icon-default": expatCoreTheme.colors["neutral-50"],
    "icon-disabled": expatCoreTheme.colors["neutral-500"],
    "icon-duotone-1": expatCoreTheme.colors["brand-main-300"],
    "icon-duotone-2": expatCoreTheme.colors["secondary-color-2-300"],
    "icon-info": expatCoreTheme.colors["feedback-blue-300"],
    "icon-on-dark": expatCoreTheme.colors["neutral-900"],
    "icon-on-primary": expatCoreTheme.colors["neutral-50"],
    "icon-on-secondary": expatCoreTheme.colors["neutral-50"],
    "icon-positive": expatCoreTheme.colors["feedback-green-300"],
    "icon-subdued": expatCoreTheme.colors["neutral-200"],
    "icon-warning": expatCoreTheme.colors["feedback-orange-300"],
    "interactive-action-hovered":
      expatCoreTheme.colors["transparent-light-300"],
    "interactive-action-pressed":
      expatCoreTheme.colors["transparent-light-500"],
    "interactive-focus-default": expatCoreTheme.colors["feedback-blue-500"],
    "interactive-overlay-default":
      expatCoreTheme.colors["transparent-dark-500"],
    "interactive-overlay-image": expatCoreTheme.colors["transparent-dark-700"],
    "interactive-primary-default":
      expatCoreTheme.colors["secondary-yellow-700"],
    "interactive-primary-hovered":
      expatCoreTheme.colors["secondary-yellow-500"],
    "interactive-primary-pressed":
      expatCoreTheme.colors["secondary-yellow-900"],
    "interactive-secondary-default":
      expatCoreTheme.colors["secondary-color-2-700"],
    "interactive-secondary-hovered":
      expatCoreTheme.colors["secondary-color-2-500"],
    "interactive-secondary-pressed":
      expatCoreTheme.colors["secondary-color-2-900"],
    "surface-accent-1-primary": expatCoreTheme.colors["brand-main-700"],
    "surface-accent-1-secondary": expatCoreTheme.colors["brand-main-500"],
    "surface-accent-2-primary": expatCoreTheme.colors["secondary-color-2-700"],
    "surface-accent-2-secondary":
      expatCoreTheme.colors["secondary-color-2-900"],
    "surface-accent-3-primary": expatCoreTheme.colors["secondary-color-3-700"],
    "surface-accent-3-secondary":
      expatCoreTheme.colors["secondary-color-3-900"],
    "surface-accent-4-primary": expatCoreTheme.colors["secondary-color-3-500"],
    "surface-active": expatCoreTheme.colors["neutral-700"],
    "surface-critical": expatCoreTheme.colors["feedback-red-700"],
    "surface-disabled": expatCoreTheme.colors["neutral-700"],
    "surface-info": expatCoreTheme.colors["feedback-blue-700"],
    "surface-info-2": expatCoreTheme.colors["feedback-blue-500"],
    "surface-neutral-option-1": expatCoreTheme.colors["neutral-900"],
    "surface-neutral-option-10": expatCoreTheme.colors["neutral-50"],
    "surface-neutral-option-2": "neutral-800",
    "surface-neutral-option-3": expatCoreTheme.colors["neutral-700"],
    "surface-neutral-option-4": expatCoreTheme.colors["neutral-600"],
    "surface-neutral-option-5": expatCoreTheme.colors["neutral-500"],
    "surface-neutral-option-6": expatCoreTheme.colors["neutral-400"],
    "surface-neutral-option-7": expatCoreTheme.colors["neutral-300"],
    "surface-neutral-option-8": expatCoreTheme.colors["neutral-200"],
    "surface-neutral-option-9": expatCoreTheme.colors["feedback-red-700"],
    "surface-positive": expatCoreTheme.colors["feedback-green-700"],
    "surface-promo": expatCoreTheme.colors["secondary-color-2-700"],
    "surface-select": expatCoreTheme.colors["secondary-color-2-700"],
    "surface-warning": expatCoreTheme.colors["feedback-orange-700"],
    "text-accent-1-primary": expatCoreTheme.colors["brand-main-100"],
    "text-accent-3-primary": expatCoreTheme.colors["secondary-color-3-300"],
    "text-accent-4-primary": expatCoreTheme.colors["secondary-color-1-300"],
    "text-on-primary-default": expatCoreTheme.colors["neutral-900"],
    "text-accent-4-secondary": expatCoreTheme.colors["secondary-color-1-500"],
    "text-body": expatCoreTheme.colors["feedback-red-700"],
    "text-critical": expatCoreTheme.colors["feedback-red-300"],
    "text-disabled": expatCoreTheme.colors["neutral-600"],
    "text-functional": expatCoreTheme.colors["neutral-100"],
    "text-functional-subtle": expatCoreTheme.colors["neutral-200"],
    "text-info": expatCoreTheme.colors["feedback-blue-300"],
    "text-on-primary": expatCoreTheme.colors["neutral-50"],
    "text-on-secondary": expatCoreTheme.colors["neutral-50"],
    "text-placeholder": expatCoreTheme.colors["neutral-300"],
    "text-positive": expatCoreTheme.colors["feedback-green-300"],
    "text-select": expatCoreTheme.colors["feedback-blue-500"],
    "text-title": expatCoreTheme.colors["neutral-50"],
    "text-warning": expatCoreTheme.colors["feedback-orange-300"],
  },
};

export default expatColorMap;
