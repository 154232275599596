/* eslint-disable spaced-comment */
const avanti = {
  colors: {
    "brand-main-100": "#e0f1eb",
    "brand-main-300": "#c0e1d8",
    "brand-main-500": "#289292",
    "brand-main-700": "#005a5e",
    "brand-main-900": "#164648",

    "feedback-blue-100": "#edf2ff",
    "feedback-blue-300": "#d3e0fe",
    "feedback-blue-500": "#368fe9",
    "feedback-blue-700": "#006bd6",

    "feedback-green-100": "#e8f7e9",
    "feedback-green-300": "#c5f6c5",
    "feedback-green-500": "#72cc72",
    "feedback-green-700": "#008205",

    "feedback-orange-100": "#fff0e9",
    "feedback-orange-300": "#ffd4bb",
    "feedback-orange-500": "#e17a40",
    "feedback-orange-700": "#d06d10",

    "feedback-red-100": "#ffefef",
    "feedback-red-300": "#fd8e8b",
    "feedback-red-500": "#df413c",
    "feedback-red-700": "#ca302b",

    "neutral-default": "#272427",
    "neutral-100": "#f2ede8",
    "neutral-200": "#e6e1dd",
    "neutral-300": "#dcd8d3",
    "neutral-400": "#d2ceca",
    "neutral-50": "#ffffff",
    "neutral-500": "#b3afac",
    "neutral-600": "#7d7b78",
    "neutral-700": "#4f4d4c",
    "neutral-800": "#2e2d2c",
    "neutral-900": "#272427",
    "neutral-1000": "#4F4F4F",

    //sunset
    "secondary-color-1-300": "#ffecb2",
    "secondary-color-1-500": "#ffd873",
    "secondary-color-1-700": "#f6b052",
    "secondary-color-1-900": "#faa019",

    //azure-blue
    "secondary-color-2-300": "#eaeefb",
    "secondary-color-2-500": "#c4d0f5",
    "secondary-color-2-700": "#6f8de4",
    "secondary-color-2-900": "#4267d2",

    //coral
    "secondary-color-3-300": "#fdadb2",
    "secondary-color-3-500": "#ff999f",
    "secondary-color-3-700": "#e2005d",
    "secondary-color-3-900": "#d10048",

    //pink
    "secondary-color-4-300": "#ffdde2",
    "secondary-color-4-500": "#ffcad2",
    "secondary-color-4-700": "#ffb0bf",
    "secondary-color-4-900": "#fc97af",

    //bright-green
    "secondary-color-5-300": "#e1edd5",
    "secondary-color-5-500": "#d2e2c2",
    "secondary-color-5-700": "#39c453",
    "secondary-color-5-900": "#28a745",

    "transparent-dark-500": "#2e2e2e8f",
    "transparent-dark-700": "#2e2e2ecc",
    "transparent-dark-900": "#2724273D",
    "transparent-grey-300": "#7d7d7d14",
    "transparent-grey-500": "#7d7d7d3d",
    "transparent-light-300": "#ffffff14",
    "transparent-light-500": "#ffffff3d",
    "transparent-light-700": "#ffffffcc",

    // for type issue fixed
    "transparent-overlay": "rgba(0, 0, 0, 0.24)", // not included figma
    "transparent-overlay-1": "rgba(0, 0, 0, 0.25)", // not included figma
    "brand-purple-transparent": "rgba(0, 0, 0, 0.30)", // not included figma
    "gray-transparent": "#ffffff4d", // not included figma
    "secondary-yellow-300": "#ffe6a6",
    "secondary-yellow-500": "#ffd873",
    "secondary-yellow-700": "#ffbb0d",
    "secondary-yellow-900": "#fb9e16",
  },
};

export default avanti;
