import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";
import avantiColorMap from "./avanti-color-map";
import stsColorMap from "./sts-color-map";
import expatColorMap from "./expat-color-map";

const colors = {
  avanti: avantiColorMap,
  staysure: stsColorMap,
  expat: expatColorMap,
};

export const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

const selectedColorBrand = colors[brand];

export default selectedColorBrand;
