const styles = {
  colors: {
    "brand-main-100": "#e3d6f1",
    "brand-main-300": "#c2b1d6",
    "brand-main-500": "#7c628d",
    "brand-main-700": "#522367",
    "brand-main-900": "#2A0F33",

    "feedback-blue-100": "#edf2ff",
    "feedback-blue-300": "#d3e0fe",
    "feedback-blue-500": "#368fe9",
    "feedback-blue-700": "#006bd6",

    "feedback-green-100": "#e8f7e9",
    "feedback-green-300": "#c5f6c5",
    "feedback-green-500": "#72cc72",
    "feedback-green-700": "#008205",

    "feedback-orange-100": "#fff0e9",
    "feedback-orange-300": "#ffd4bb",
    "feedback-orange-500": "#e17a40",
    "feedback-orange-700": "#d06d10",

    "feedback-red-100": "#ffefef",
    "feedback-red-300": "#fd8e8b",
    "feedback-red-500": "#df413c",
    "feedback-red-700": "#ca302b",

    "neutral-100": "#f0f0f0",
    "neutral-200": "#e6e6e6",
    "neutral-300": "#dcdcdc",
    "neutral-400": "#d2d2d2",
    "neutral-50": "#ffffff",
    "neutral-500": "#b3b3b3",
    "neutral-600": "#7d7d7d",
    "neutral-700": "#4f4f4f",
    "neutral-800": "#2e2e2e",
    "neutral-900": "#272427",

    // sunset
    "secondary-color-1-300": "#ffecb2",
    "secondary-color-1-500": "#ffd873",
    "secondary-color-1-700": "#f6b052",
    "secondary-color-1-900": "#faa019",

    // teal
    "secondary-color-2-300": "#d7e8f0",
    "secondary-color-2-500": "#83afc3",
    "secondary-color-2-700": "#306582",
    "secondary-color-2-900": "#28566e",

    // magenta
    "secondary-color-3-300": "#d1c0d6",
    "secondary-color-3-500": "#a566ad",
    "secondary-color-3-700": "#8f3f96",
    "secondary-color-3-900": "#762c7d",

    "secondary-yellow-300": "#ffe6a6",
    "secondary-yellow-500": "#ffd873",
    "secondary-yellow-700": "#ffbb0d",
    "secondary-yellow-900": "#fb9e16",

    "transparent-dark-500": "#2e2e2e8f",
    "transparent-dark-700": "#2e2e2ecc",
    "transparent-dark-900": "#2724273D",
    "transparent-grey-300": "#7d7d7d14",
    "transparent-grey-500": "#7d7d7d3d",
    "transparent-light-300": "#ffffff14",
    "transparent-light-500": "#ffffff3d",
    "transparent-light-700": "#ffffffcc",

    "transparent-overlay": "rgba(0, 0, 0, 0.24)", // not included figma
    "transparent-overlay-1": "rgba(0, 0, 0, 0.25)", // not included figma
    "brand-purple-transparent": "#2724273D", // not included figma
    "gray-transparent": "#ffffff4d", // not included figma
  },
};
export default styles;
